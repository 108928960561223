import { getRawStoredValue, setRawStoredValue } from "./settings";

export const round = (value: number, decimals: number) => {
    return Number(Math.round(Number(`${value}e${decimals}`)) + 'e-' + decimals);
}

function throwError(errorMessage: string): never {
    throw new Error(errorMessage);
}

export interface RandomStringGenerator {
    getNext(): string;
}

/**
 * Returns a new string every time, and avoid showing the ones already shown. 
 * Uses settings to persist data.
 */
export class PersistentRandomStringGenerator implements RandomStringGenerator {
    private readonly name: string;
    private readonly stringList: string[];
    private readonly usedIndices: Set<number>;

    constructor(name: string, stringList: string[]) {
        this.name = name ?? throwError('name cannot be null');
        this.stringList = stringList ?? throwError('stringList cannot be null');
        const storedVals = getRawStoredValue(`prsg-${this.name}`, []); // empty array by default
        this.usedIndices = new Set(storedVals);
    }

    getNext(): string {
        if (this.usedIndices.size === this.stringList.length) {
            this.reset();
        }

        let randomIndex: number;
        do {
            randomIndex = Math.floor(Math.random() * this.stringList.length);
        } while (this.usedIndices.has(randomIndex));

        this.usedIndices.add(randomIndex);
        setRawStoredValue(`prsg-${this.name}`, Array.from(this.usedIndices)); // store as array

        return this.stringList[randomIndex];
    }

    private reset(): void {
        this.usedIndices.clear();
    }
}

function createDateInTimezone(originalDate: Date, timezoneOffsetHours: number) {
    // Get the original date's timestamp in milliseconds
    const originalTimestamp = originalDate.getTime();

    // Convert the timezone offset from minutes to milliseconds
    const offsetMs = timezoneOffsetHours * 60 * 60 * 1000;

    // Adjust the timestamp to the desired timezone
    const adjustedTimestamp = originalTimestamp + offsetMs;

    // Create a new Date object with the adjusted timestamp
    return new Date(adjustedTimestamp);
}

// Create a version number based on the date
export function createVersion(versionDate = new Date()) {
    const padZeros = (val: number, len = 2) => String(val).padStart(len, '0');
    // make sure the date is always in the same timezone no matter where the build is
    const [month, day, year] = versionDate.toLocaleDateString('en-US', { timeZone: 'America/Chicago' }).split('/');
    const [hour, minute, second] = versionDate.toLocaleTimeString('fr-FR', { timeZone: 'America/Chicago' }).split(':'); // 24h format

    const dayOfYearDiff = Date.UTC(Number(year), Number(month) - 1, Number(day)) - Date.UTC(Number(year), 0, 0);
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(dayOfYearDiff / oneDay);


    // (year - 2022).(day of year). ex: v1.329.2420, code=13292420
    const major = Number(year) - 2022  // starts at "1" in year 2022
    const minor = padZeros(dayOfYear, 3); // 001 - 366
    const point = hour + minute;
    const versionStr = `${major}.${minor}.${point}`;
    const versionCode = Number(`${major}${minor}${point}`);

    // validity check
    const maxVersion = 2100000000; // Android max versionCode is 2100000000
    if (versionCode >= maxVersion || //
        versionCode < 10000000 || versionCode > 100000000 // expected range
    ) {
        throw new Error(`Invalid version code ${versionCode} (${versionStr})`)
    }

    return [versionStr, versionCode]
}