<script lang="ts">
  import { Icon, List, ListItem, Navbar, Page } from "framework7-svelte";
  import {
    getLanguage,
    languages,
    setLanguage,
    translate as trsl
  } from "../js/i18n.ts";

  import { f7, f7ready } from "framework7-svelte";
  import { onMount } from "svelte";
  import { Event, recordEvent } from "../js/analytics.ts";
  import { browseURL, device, openEmail, shareApp } from "../js/app.ts";
  import { EMAIL_ADDRESS } from "../js/media.ts";

  let langSelectEl: ListItem;
  let currentLang = getLanguage();
  let tr = trsl; // store translate fn so it can be refresh on lang chjange for svelte to update ui

  const rateApp = () => {
    f7.dialog
      .create({
        title: tr("common.rate_app"),
        text: tr("settings.rate_app_question"),
        buttons: [
          {
            text: tr("common.cancel")
          },
          {
            text: tr("common.yes"),
            strong: true
          },
          {
            text: tr("common.no")
          }
        ],
        onClick: (_, index) => {
          if (index == 1) {
            // analytics
            recordEvent(Event.Share, {
              content_type: "happy",
              method: "market"
            });

            // user happy, redirect to store for rating
            if (device.android) {
              // browseURL("market://details?id=com.genux.coherence");
              browseURL(
                "https://play.google.com/store/apps/details?id=com.genux.coherence"
              );
            }

            f7.dialog.alert(tr("common.thank_you"));
          } else if (index == 2) {
            // user not happy, send an email instead of rating on the stores
            recordEvent(Event.Share, {
              content_type: "nothappy",
              method: "market"
            });

            openEmail(
              EMAIL_ADDRESS,
              "Rating Feedback", // no-translate
              "Hi, I have feedback for Coherence" // no-translate
            );
          }
        }
      })
      .open();
  };

  const onShare = () => {
    shareApp().then(() => f7.dialog.alert(tr("common.thank_you")));
  };

  onMount(() => {
    f7ready(() => {
      const smartSelect = langSelectEl.smartSelectInstance();
      smartSelect.on("close", () => {
        const lang = smartSelect.getValue();
        currentLang = lang;
        setLanguage(lang);
        // forece refresh UI for lang to change
        tr = trsl;
      });
    });
  });
</script>

<Page name="settings">
  <Navbar title={tr("settings.title")} backLink="Back"></Navbar>

  <List>
    <li>
      <a class="item-link item-content" href="/settings/about/">
        <div class="item-media">
          <img src="/icons/icon.svg" alt="Coherence Logo" />
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.about")}</div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" href="/settings/session/">
        <div class="item-media">
          <i class="icon material-icons">headphones</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.session_media")}</div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" href="/settings/statistics/">
        <div class="item-media">
          <i class="icon material-icons">bar_chart</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.statistics")}</div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" href="/settings/notifications/">
        <div class="item-media">
          <i class="icon material-icons">alarm</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("settings.notifications")}</div>
        </div>
      </a>
    </li>
    <ListItem
      title={tr("settings.language")}
      smartSelect
      bind:this={langSelectEl}>
      <Icon slot="media" material="language" size="32"></Icon>
      <select name="language">
        {#each Object.entries(languages) as [code, name]}
          {#if code == currentLang}
            <option selected value={code}>{name}</option>
          {:else}
            <option value={code}>{name}</option>
          {/if}
        {/each}
      </select>
    </ListItem>
    <li>
      <a class="item-link item-content" href="/settings/licenses/">
        <div class="item-media">
          <i class="icon material-icons">people</i>
        </div>
        <div class="item-inner">
          <div class="item-title">
            {tr("settings.credits_and_licenses")}
          </div>
        </div>
      </a>
    </li>
    <li>
      <a class="item-link item-content" on:click={onShare} href={"#"}>
        <div class="item-media">
          <i class="icon material-icons">share</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("common.share")}</div>
        </div>
      </a>
    </li>
    {#if device.native}
      <ListItem title={tr("common.rate_app")} link={"#"} onClick={rateApp}>
        <Icon slot="media" material="thumb_up" size="32"></Icon>
      </ListItem>
    {/if}
  </List>
</Page>

<style>
  .item-content .material-icons {
    font-size: 32px;
  }
</style>
