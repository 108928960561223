<script>
  import { f7, Fab, Icon, Page } from "framework7-svelte";
  import SessionFinishPage from "../components/session-finish-page.svelte";
  import MessageComponent from "../components/session-messages.svelte";
  import SettingsComponent from "../components/session-settings.svelte";
  import SunriseAnim from "../components/sunrise-anim.svelte";
  import {
    recordEventSessionEnd,
    recordEventSessionStart
  } from "../js/analytics.ts";
  import {
    device,
    dispatch,
    EVENT,
    subscribe,
    unsubscribe
  } from "../js/app.ts";
  import { translate as tr } from "../js/i18n.ts";
  import {
    BREATH_IN_PATH,
    BREATH_OUT_PATH,
    CHIMES_1,
    DEFAULT_BG_MUSIC_PATH,
    DriftCorrectingTimer,
    MediaPlayer,
    setAnimationsEnabled,
    setFullScreen,
    vibrate
  } from "../js/media.ts";
  import { ROUTE_STATS } from "../js/routes.ts";
  import { preventScreenSleep } from "../js/session.ts";
  import {
    addSessionStats,
    getSetting,
    isSettingEnabled,
    SessionType,
    SETTINGS
  } from "../js/settings.ts";

  export let f7router;
  export let notui = false;
  export let intro = false;
  export let duration = 5;

  let progressRef = 0;
  let finishPageEl;

  const props = {
    // when it's not a UI event disable wake lock use
    notui: notui === true ? true : false
  };

  var isPaused;
  var useWakeLock = props.notui ? false : true; // when it's not a UI event disable wake lock use

  const isBrowser = device.web && !device.pwa; // check not installed as pwa
  var buttonVisible = true;
  const setControlsVisible = (visible) => {
    if (visible === buttonVisible) return;
    const opacity = visible ? 1 : 0;
    buttonVisible = visible;
    if (buttonVisible) {
      schedulePauseButtonHide(); // autohide
    }

    // animate not working for some reason
    f7.$(".fab.fab-no-bg.fade").css(
      { opacity: opacity }
      // { duration: 400, easing: "swing" }
    );
  };

  var blockSleep = (block) => {
    if (useWakeLock) {
      preventScreenSleep(block);
    }
  };

  var buttonTimer = null;
  const schedulePauseButtonHide = () => {
    // cancel previous timer is any
    if (buttonTimer !== null) {
      window.clearTimeout(buttonTimer);
    }
    // schedule hide
    buttonTimer = window.setTimeout(() => {
      setControlsVisible(false);
      buttonTimer = null;
    }, 3000); // 3 sec hide
  };

  const toggleButton = () => {
    setControlsVisible(!buttonVisible); // show / hide
  };

  const setAnimationsEnabld = (enabled) => {
    setAnimationsEnabled(f7.$(".landscape")[0], enabled);
  };

  const updateProgress = (pct) => {
    progressRef = pct; // update progress
  };

  const navigateStats = () => {
    f7router.navigate(ROUTE_STATS, {
      animate: true,
      clearPreviousHistory: true
    });
  };

  const navigateHome = () => {
    f7router.navigate("/", {
      animate: true,
      clearPreviousHistory: true
    });
  };

  const startDate = new Date();
  let ignoredSeconds = 0; // ignore stopped time, when user stops but continues.

  const getElapsedTimeSecs = () => {
    return (
      Math.round((Date.now() - startDate.getTime()) / 1000) - ignoredSeconds
    ); // cleanup to int, add stopped time
  };

  /**
   * FINISH
   */
  let finishPausedStartDate;
  let timerElapsedSecs; //TODO: svelte hacky
  const incrementStoppedTime = () => {
    const stoptimeSecs = Math.round(
      (Date.now() - finishPausedStartDate.getTime()) / 1000
    ); // cleanup to int
    ignoredSeconds += stoptimeSecs; // increase ignoredSeconds with the time it took to hide the dialog
  };

  const finishPushed = () => {
    showFinishPage(getElapsedTimeSecs());
  };

  const onFinish = () => {
    incrementStoppedTime();
    sessionStop(true); // save stats
    navigateHome();
  };

  const onDiscard = () => {
    sessionStop(false); // do not save stats
    navigateHome();
  };

  // CONTINUE pressed
  const onResume = () => {
    incrementStoppedTime(); // add stopped time while the dialog was opened
    finishPausedStartDate = null; // end increased time

    finishPageEl.close();
    sessionPause(false); // unpause session
  };

  const showFinishPage = (timeSecs) => {
    if (finishPausedStartDate != null) return; // already shown
    // start counting paused time
    finishPausedStartDate = new Date();
    // pause session
    sessionPause(true);
    // show page
    timerElapsedSecs = timeSecs; //TODO: svelte hacky
    finishPageEl.open();
  };

  const onSessionComplete = () => {
    showFinishPage(getElapsedTimeSecs());
    // play end sound if any sound enabled
    if (
      isSettingEnabled(SETTINGS.sound_effects_enabled) ||
      isSettingEnabled(SETTINGS.background_music_enabled)
    ) {
      finishedMediaPlayer.start();
    }
  };

  const firstSession = intro === true;
  const backgroundMediaPlayer = new MediaPlayer(DEFAULT_BG_MUSIC_PATH); // looping
  const breathInMediaPlayer = new MediaPlayer(BREATH_IN_PATH, false); // not looping
  const breathOutMediaPlayer = new MediaPlayer(BREATH_OUT_PATH, false); // not looping
  const finishedMediaPlayer = new MediaPlayer(CHIMES_1, false); // not looping

  const isSleepMode = intro === "sleep";

  var vibrationIter = 0;
  var vibrationEnabled = isSettingEnabled(SETTINGS.vibrations_enabled);
  const vibrateTimerFunc = () => {
    if (!vibrationEnabled) return;
    if (vibrationIter < 5) vibrate(100); // VIBRATE
    vibrationIter++;
  };

  var countDownDate;
  const minutes = firstSession ? 2 : duration || 5; // 2 minutes first time, or 5 min default

  let animationTime = getSetting(SETTINGS.breathing_time) || 5;

  let timerRef = "";
  const updateTimerText = (text) => {
    timerRef = text;
  };

  var countDownComplete = false;
  const timerFunc = () => {
    if (!countDownDate) return;
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = countDownDate - now + ignoredSeconds * 1000;
    // Time calculations for days, hours, minutes and seconds
    // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let secs = Math.floor((distance % (1000 * 60)) / 1000);
    let breaths = Math.ceil((secs + mins * 60) / animationTime / 2);
    // Display the result in the element with id="timer"
    if (distance < 0) {
      // TODO use ref variable, better math, use constants
      mins = Math.ceil(mins - (distance % (1000 * 60 * 60)) / (1000 * 60));
      secs = Math.ceil((distance % (1000 * 60)) / 1000);
      breaths = Math.floor((secs + mins * 60) / animationTime / 2);
    }

    let timerText = `${tr("session.time_left", { mins: mins, secs: secs })}, ${tr("session.breath_left", { breaths: breaths })}`;
    timerText = timerText.replace(/-/g, "+"); // show extra time as + instead of -
    updateTimerText(timerText);

    const progress = 100 - Math.round((100 * distance) / (minutes * 60 * 1000));
    // If the count down is finished, write some text
    // TODO: find another way the ncountDownComplete to not repeat when timer is finished
    if (distance < 0 && !countDownComplete) {
      updateTimerText("Done");
      updateProgress(100); // max out progress bar
      onSessionComplete();
      countDownComplete = true;
    } else {
      // update progress
      if (progress < 0) {
        console.error("bad progress", progress);
      }
      updateProgress(progress);
      f7.progressbar.set("#progress", progress);
    }
  };

  const onBreathChange = (isBreathIn) => {
    if (isSettingEnabled(SETTINGS.sound_effects_enabled)) {
      if (isBreathIn) {
        breathInMediaPlayer.start();
        breathOutMediaPlayer.stop();
      } else {
        breathOutMediaPlayer.start();
        breathInMediaPlayer.stop();
      }
    }

    // vibrations
    vibrateTimer.stop(); // stop vibration to make sure we're in sync
    vibrationIter = 0; // dirty hacky
    if (isBreathIn) {
      vibrateTimer.start(); // resync vibration to animation
    }
  };

  var breathIn = true;
  const breathTimerFunc = () => {
    onBreathChange(breathIn);
    breathIn = !breathIn;
  };

  const breathTimer = new DriftCorrectingTimer(
    breathTimerFunc,
    animationTime * 1000
  );
  const vibrateTimer = new DriftCorrectingTimer(vibrateTimerFunc, 1000);
  const elapsedTimer = new DriftCorrectingTimer(timerFunc, 100); // update every 100ms

  const firstStart = () => {
    sessionStart();

    // after this initial start we can use the wake lock for sure
    useWakeLock = true;
  };

  let fullscreen = false;
  const toggleFullScreen = () => {
    fullscreen = !fullscreen;
    setFullScreen(fullscreen);
  };

  const sessionStart = () => {
    countDownDate = new Date(Date.now() + minutes * 60 * 1000).getTime();
    ignoredSeconds = 0; // reset ignored seconds. TODO: is this needed ?

    sessionPause(false);
    // let listeners know we're starting a session
    dispatch(EVENT.sessionStateChange, true);
    // analytics
    recordEventSessionStart(minutes);
  };

  function sessionStop(saveStats = true) {
    const completedSecs = getElapsedTimeSecs();
    if (saveStats) {
      addSessionStats({
        // store stats
        date: startDate.getTime(),
        elapsed: completedSecs,
        type: SessionType.coherence
      });
    }
    // let listeners know we're ending a session
    dispatch(EVENT.sessionStateChange, false);
    // analytics
    if (saveStats && completedSecs > 1) {
      recordEventSessionEnd(completedSecs / 60); // record minutes
    }
    // stop blocking sleep
    blockSleep(false);
  }

  function sessionPause(paused) {
    isPaused = paused;

    if (paused) {
      // pause
      elapsedTimer.stop();
      breathTimer.stop();
      vibrateTimer.stop();
      backgroundMediaPlayer.stop();
      breathInMediaPlayer.stop();
      breathOutMediaPlayer.stop();
    } else {
      // start
      elapsedTimer.start();
      if (isSettingEnabled(SETTINGS.background_music_enabled)) {
        backgroundMediaPlayer.start();
      }
      breathTimer.start();
      schedulePauseButtonHide();
    }

    setAnimationsEnabld(!paused && !isSleepMode);
    updateMessageEnabled(!paused);
    // take/release scfeen lock
    blockSleep(!paused);
  }

  const updateMessageEnabled = (enabled) => {
    const msgEnabled = enabled
      ? isSettingEnabled(SETTINGS.messages_enabled)
      : false;
    const msgEl = f7.$("#messages");
    if (msgEl) {
      if (msgEnabled) {
        msgEl.show();
      } else {
        msgEl.hide();
      }
    }
  };

  const onSettingsChange = (settings) => {
    console.debug("settings changed" + JSON.stringify(settings));
    // reloadSettings(); // change global settings state
    if (!isSettingEnabled(SETTINGS.background_music_enabled)) {
      backgroundMediaPlayer.stop();
    } else {
      backgroundMediaPlayer.start();
    }

    const vibrationEnabledPrev = vibrationEnabled;
    vibrationEnabled = isSettingEnabled(SETTINGS.vibrations_enabled);
    if (vibrationEnabled && vibrationEnabledPrev != vibrationEnabled) {
      vibrate(100); // tell user we enabled vibrations
    }
    updateMessageEnabled(true);
  };

  function onPageInit() {
    subscribe(EVENT.settingsChanged, onSettingsChange);
    // fab wiil force button visible so state needs to know
    f7.$("settings-button").on("fab:close", () => {
      // setControlsVisible(false);
    });
  }

  const updateUpdateAnimationTime = (seconds) => {
    // TODO: this should work but it doesn't...
    // $(document.documentElement).css({
    //   '--anim-time': `${animationTime}s`,
    //   '--anim-time-2': `${animationTime * 2}s`
    // });

    // update animation time
    document.documentElement.style.setProperty("--anim-time", `${seconds}s`);
    document.documentElement.style.setProperty(
      "--anim-time-2",
      `${seconds * 2}s`
    );
  };

  function onPageBeforeIn() {
    // update animation time
    updateUpdateAnimationTime(animationTime);
    // bit hacky hide messages now (early) or they'll be visible for a little while when disabled
    updateMessageEnabled(false);
  }

  function onpageAfterIn() {
    // start session
    firstStart();
  }

  function onPageBeforeOut() {
    // when page is reloaded by router, make sure we close the dialog
    // TODO: can be called by router.refreshPage() here so check it's all done right
    if (!isPaused) {
      sessionPause(true);
    }
    finishPageEl.close();
  }

  function onPageBeforeRemove() {
    f7.$("#live-settings").off("form:storedata"); // remove listener
    unsubscribe(EVENT.settingsChanged, onSettingsChange);
  }
</script>

<Page
  class="session-sunrise no-default-bg"
  name="sunrise"
  onPageInit={onPageInit}
  onPageAfterIn={onpageAfterIn}
  onPageBeforeIn={onPageBeforeIn}
  onPageBeforeOut={onPageBeforeOut}
  onPageBeforeRemove={onPageBeforeRemove}>
  <div class="controls">
    <!-- settings button -->
    <Fab
      position="center-bottom"
      morphTo=".fab-fullscreen-sheet.fab-morph-target"
      class="fab-no-bg fade settings-button">
      <Icon material="settings" color="white" />
    </Fab>

    <!-- fullscreen button on browser-->
    {#if isBrowser}
      <Fab
        position="center-bottom"
        onClick={toggleFullScreen}
        class="fab-no-bg fade button-fullscreen">
        {#if fullscreen}
          <Icon material="fullscreen_exit" color="white" />
        {:else}
          <Icon material="fullscreen" color="white" />
        {/if}
      </Fab>
    {/if}
  </div>

  <!-- center sheet popup for settings -->
  <div class="fab-fullscreen-sheet fab-morph-target">
    <div class="view view-init">
      <div class="page no-default-bg settings-page">
        <div class="page-content">
          <SettingsComponent />

          <div class="block-title" id="timer">{timerRef}</div>
          <div class="block">
            <!-- Green progressbar -->
            <div
              id="progress"
              class="progressbar color-green"
              data-progress={progressRef}>
              <span></span>
            </div>
          </div>

          <div class="block" style="margin-bottom: 0;">
            <div class="grid grid-cols-2 grid-gap">
              <div>
                <a
                  href={"#"}
                  class="button button-round button-fill color-deeporange fab-close"
                  on:click={finishPushed}>{tr("common.finish")}</a>
              </div>
              <div>
                <a
                  href={"#"}
                  class="button button-round button-fill color-teal fab-close"
                  >{tr("common.continue")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SunriseAnim on:click={toggleButton} />

  <MessageComponent intro={intro} />

  <SessionFinishPage
    f7router={f7router}
    bind:this={finishPageEl}
    completedTimeSecs={timerElapsedSecs}
    onContinuePressed={onResume}
    onFinishPressed={onFinish}
    onDiscardPressed={onDiscard} />
</Page>

<style>
  :global(#live-settings.list) {
    margin: 8px 0;
  }

  .fab-morph-target {
    opacity: 0.9;
  }

  :global(.icon-badge) {
    opacity: 0;
  }

  :global(.icon.icon-badge) {
    font-size: 4em;
    color: darkgoldenrod;
  }

  :global(.md .popover.modal-in) {
    opacity: 0.9;
  }

  :global(.fab.fab-no-bg) {
    background-color: unset;
  }

  :global(.fab.fab-no-bg .icon) {
    font-size: 36px;
  }

  :global(.fab-left-bottom, .fab-center-bottom, .fab-right-bottom) {
    bottom: 16vh;
  }

  :global(.fab.button-fullscreen) {
    bottom: 10vh;
  }

  :global(.grid.finish-dialog) {
    grid-template-columns: 10fr 90fr;
    align-items: center;
  }
</style>
